<template>
  <router-view :url="url"/>
</template>

<script>
export default {
  data: () => {
    return {
      url: 'https://sample-express.nanosense-id.com'
      // url: 'http://localhost:3000'
    }
  },
}
</script>