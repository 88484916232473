<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Nanosense Sample Dashboard</h1>
            </div>
            <div class="row">
             
              <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-primary shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                  <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                      Articles Published</div>
                                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{allArticles.length}} Unit</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-xl-3 col-md-6 mb-4">
                  <div class="card border-left-success shadow h-100 py-2">
                      <div class="card-body">
                          <div class="row no-gutters align-items-center">
                              <div class="col mr-2">
                                  <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Articles Published</div>
                                  <div class="h5 mb-0 font-weight-bold text-gray-800">{{allArticles.length}}</div>
                              </div>
                              <div class="col-auto">
                                  <i class="fas fa-calendar fa-2x text-gray-300"></i>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Articles Published
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{allArticles.length}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-notes-medical fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

              <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-danger shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Articles Published
                                </div>
                                <div class="row no-gutters align-items-center">
                                    <div class="col-auto">
                                        <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">{{allArticles.length}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-auto">
                                <i class="fas fa-notes-medical fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>

            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div class="card-body">
                        <div class="row" style="padding-bottom:20px;">
                            <div class="col-6">
                                <h5>Articles Published</h5>
                            </div>
                            <div class="col-6">
                                <input v-model="filterTable" type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search">
                            </div>
                        </div>
                        <div class="row">
                            <b-table
                                bordered
                                striped
                                hover
                                show-empty
                                empty-text="There are no records to show"
                                index :items="allArticles"
                                :fields="fields"
                                :filter="filterTable"
                                :per-page="perPage"
                                :current-page="currentPage"
                                responsive
                            >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template #cell(owner)="data">
                                    {{ data.item.owner.name }}
                                </template>
                                <template #cell(owneremail)="data">
                                    {{ data.item.owner.email }}
                                </template>
                                <template #cell(action)="data">
                                    <button @click="getDetail(data.item._id)" class="btn btn-warning btn-circle btn-sm">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                >
                                </b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
// import '@netcz/vue-pikaday'
// import moment from 'moment'

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
      return {
        //   date: null,
          userInfo: {},
          allArticles: [],
          filterTable: "",
          perPage: 10,
          currentPage: 1,
          fields: [
                {
                    key: 'index',
                    label: 'No'
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'content',
                    label: 'Content',
                    sortable: true
                },
                {
                    key: 'owner',
                    label: 'Owner',
                    sortable: true
                },
                {
                    key: 'owneremail',
                    label: 'Owner Email',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Detail'
                },
          ]
      }
  },
  methods : {
      getUserInfo() {
        axios.get(`${this.url}/users/${localStorage.getItem('user_id')}`)
        .then((response) => {
            let userInfo = response.data.data
            this.userInfo = userInfo
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getArticles() {
        axios.get(`${this.url}/articles/all`)
        .then((response) => {
            this.allArticles = response.data.data
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getDetail(id) {
          let routeData = this.$router.resolve(`/detail/${id}`);
          window.open(routeData.href, '_blank');
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else {
        this.getArticles()
        this.getUserInfo()
      }
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
       }
  },
  computed: {
        rows() {
            return this.allArticles.length
        }
    }
}
</script>
