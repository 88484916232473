<template>
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center">
            <div class="sidebar-brand-icon">
                <img class="sidebar-brand-icon" src="/img/Logo-Nanosense-Crop-White.png" width="70">
            </div>
            <div class="sidebar-brand-text mx-3">Sample Dashboard</div>
        </a>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <!-- Nav Item - Dashboard -->
        <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <router-link to="/profile" class="nav-link">
                <i class="fas fa-fw fa-user"></i>
                <span>Profile</span></router-link>
        </li>

        <!-- Divider -->
        <hr class="sidebar-divider my-0">

        <li class="nav-item">
            <router-link to="/article" class="nav-link">
                <i class="fas fa-fw fa-newspaper"></i>
                <span>Create Article</span></router-link>
        </li>

        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
    </ul>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>
