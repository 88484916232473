<template>
  <div class="home">
    <div id="wrapper">
      <Sidebar :role="userInfo.role"/>
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <Navbar :name="userInfo.name"/>
          <div class="container-fluid">
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Detail Elto</h1>
            </div>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <div v-if="elto" class="card-body">
                        <div style="text-align:center;">
                            <h4>Detail Elto - {{elto.idlaptop}}</h4>
                        </div>
                        <br>
                        <div class="row" style="padding-bottom:50px;">
                            <div class="col-xl-6 col-md-6 mb-4">
                                <br><br>
                                <p>Name User: {{elto.name}}</p>
                                <p>Company: {{elto.company}}</p>
                                <p>email: {{elto.email}}</p>
                                <p>Phone: <a :href="`https://wa.me/62${elto.phone.substring(1)}`" target="_blank">{{elto.phone}} <i class="fab fa-whatsapp fa-lg" style="color:green; padding-left:10px;"></i></a></p>
                                <p>Address: {{elto.address}}</p>
                                <p>Id Laptop: {{elto.idlaptop}}</p>
                                
                            </div>
                            <div class="col-xl-6 col-md-6 mb-4">
                                <br><br>
                                <p>Status: {{elto.status}}</p>
                                <div v-if="elto.status==`REQUEST`">
                                    <p>Approve Request and change status from REQUEST to REGISTERED</p>
                                    <button
                                        class="d-sm-inline-block btn btn-sm btn-success shadow-sm"
                                        @click="changeStatus(`REGISTERED`)"
                                        >
                                        <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                        Approve Request
                                    </button>
                                    <br>
                                    <br><br>
                                    <p>Reject request if the registration data does not meet the requirements</p>
                                    <button
                                        class="d-sm-inline-block btn btn-sm btn-warning shadow-sm"
                                        @click="changeStatus(`REJECTED`)"
                                        >
                                        <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                        Reject Request
                                    </button>
                                </div>
                                <div v-if="elto.status==`REGISTERED`">
                                    <p>Disable it first if you want to move the gad software to another laptop</p>
                                    <button
                                        class="d-sm-inline-block btn btn-sm btn-danger shadow-sm"
                                        @click="changeStatus(`DISABLED`)"
                                        >
                                        <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                        Disable Registration
                                    </button>
                                </div>
                                <div v-if="elto.status==`REJECTED` || elto.status==`DISABLED` ">
                                    <p>Please don't just delete the record unless it's really no longer needed</p>
                                    <button
                                        class="d-sm-inline-block btn btn-sm btn-danger shadow-sm"
                                        @click="deleteRecord()"
                                        >
                                        <i class="fas fa-file-csv fa-sm text-white-50"></i>
                                        Delete Record
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <p>Copyright 2022 Nanosense Instrument Indonesia. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Swal from 'sweetalert2'
// import FileSaver from 'file-saver'


export default {
  name: 'Detail',
  components: {
    Navbar,
    Sidebar
  },
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
          elto: {},
          approvalToken: "",
          loaded: false,
      }
  },
  methods : {
      getUserInfo() {
        let config = {
            method: 'POST',
            headers: {'x-auth-login-token': localStorage.getItem('token_monitor')},
            baseURL: `${this.url}/user/check`,
        }
        axios(config)
        .then((response) => {
            let userInfo = response.data.data
            if (userInfo.role == 'admin' || userInfo.role == 'superadmin') {
                this.userInfo = userInfo
            }
            else {
                this.$router.push('/dashboard')
                alert('You are not authorized to access this page')
            }
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
      getElto() {
          axios.get(`${this.url}/elto/id/${this.$route.params.id}`)
            .then((response) => {
                this.elto = response.data.data
            })
            .catch((error) => {
                console.log(error)
            })
      },
      changeStatus(status) {
          let obj = {
              status: status
          }

          Swal.fire({
                title: `Do you want to change status to ${status}`,
                showCancelButton: true,
                confirmButtonText: `Yes`,
          }).then((result) => {
                if (result.isConfirmed) {
                    axios.patch(`${this.url}/elto/id/${this.$route.params.id}`, obj)
                          .then((response) => {
                              this.elto = response.data.data
                              Swal.fire('Status Changed!', '', 'success')
                          })
                          .catch((error) => {
                              Swal.fire('Something went wrong!', '', 'error')
                              console.log(error)
                          })
                }
          })

      },
      deleteRecord() {
          Swal.fire({
                title: `Do you want to delete this record?`,
                showCancelButton: true,
                confirmButtonText: `Yes`,
          }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${this.url}/elto/id/${this.$route.params.id}`)
                        .then((response) => {
                              this.elto = response.data.data
                              Swal.fire('Record Deleted!', '', 'success')
                          })
                          .catch((error) => {
                              Swal.fire('Something went wrong!', '', 'error')
                              console.log(error)
                          })
                }
          })

      },
      copyToken() {
          let tokencopy = document.querySelector('#token-copy')
          tokencopy.select()
          try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            alert('Approval token was copied ' + msg);
          }
          
          catch (err) {
            alert('Oops, unable to copy');
          }
      },
  },
  mounted() {
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
      }
      else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
  },
  created() {
      if (!localStorage.getItem("token_monitor")) {
          this.$router.push('/')
      }
      else{
          this.getUserInfo()
          this.getElto()
      }
  }
}
</script>
